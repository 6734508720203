var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'message-input-toolbar',
    !_setup.isPC && 'message-input-toolbar-h5',
    _setup.isUniFrameWork && 'message-input-toolbar-uni',
  ]},[_c('div',{class:[
      'message-input-toolbar-list',
      !_setup.isPC && 'message-input-toolbar-h5-list',
      _setup.isUniFrameWork && 'message-input-toolbar-uni-list',
    ]},[(!_setup.isUniFrameWork)?_c(_setup.EmojiPicker,{on:{"insertEmoji":_setup.insertEmoji,"dialogShowInH5":_setup.dialogShowInH5,"dialogCloseInH5":_setup.dialogCloseInH5}}):_vm._e(),(_setup.isUniFrameWork)?_c(_setup.ImageUpload,{attrs:{"imageSourceType":"camera"}}):_vm._e(),_c(_setup.ImageUpload,{attrs:{"imageSourceType":"album"}}),(!_setup.isUniFrameWork)?_c(_setup.FileUpload):_vm._e(),_c(_setup.VideoUpload,{attrs:{"videoSourceType":"album"}}),(_setup.isUniFrameWork)?_c(_setup.VideoUpload,{attrs:{"videoSourceType":"camera"}}):_vm._e(),_c(_setup.Evaluate),_c(_setup.Words),_c(_setup.Export)],1),(_setup.isH5)?_c('div',{ref:"h5Dialog",class:['message-input-toolbar-h5-dialog']}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }