const TUIGroup = {
  "输入groupID搜索": "输入groupID搜索",
  "群名称": "群名称",
  "群ID": "群ID",
  "加入群聊": "加入群聊",
  "解散群聊": "解散群聊",
  "退出群聊": "退出群聊",
  "群类型": "群类型",
  "请填写验证信息": "请填写验证信息",
  "申请加入": "申请加入",
  "已申请": "已申请",
  "群公告": "群公告",
  "群成员": "群成员",
  "群管理": "群管理",
  "好友工作群": "好友工作群",
  "陌生人社交群": "陌生人社交群",
  "临时会议群": "临时会议群",
  "直播群": "直播群",
  "自由加入": "自由加入",
  "需要验证": "需要验证",
  "禁止加群": "禁止加群",
  "人": "人",
  "群头像": "群头像",
  "加群方式": "加群方式",
  "转让群组": "转让群组",
  "退出群组": "退出群组",
  "群管理员": "群管理员",
  "全员禁言": "全员禁言",
  "全员禁言开启后，只允许群主和管理员发言。": "全员禁言开启后，只允许群主和管理员发言。",
  "单独禁言人员": "单独禁言人员",
  "删除成员": "删除成员",
  "确定从群聊中删除该成员？": "确定从群聊中删除该成员？",
  "确定从群聊中删除所选成员？": "确定从群聊中删除所选成员？",
  "暂无公告": "暂无公告",
  "发布": "发布",
  "编辑": "编辑",
  "查看更多": "查看更多",
  "管理员": "管理员",
  "群主": "群主",
  "我": "我",
  "确认": "确认",
  "添加成员": "添加成员",
  "新增管理员": "新增管理员",
  "移除管理员": "移除管理员",
  "新增禁言用户": "新增禁言用户",
  "移除禁言用户": "移除禁言用户",
  "修改群聊名称": "修改群聊名称",
  "修改群聊名称后，将在群内通知其他成员": "修改群聊名称后，将在群内通知其他成员",
  "仅限中文、字母、数字和下划线，2-20个字": "仅限中文、字母、数字和下划线，2-20个字",
  "请先注册 TUIGroup 模块": "请先注册 TUIGroup 模块",
  "该用户不存在": "该用户不存在",
  "该用户不在群组内": "该用户不在群组内"
};

export default TUIGroup;
