<template>

  <ToolbarItemContainer
      :iconFile="exportIconFile"
      title="常用语"
      :needBottomPopup="true"
      :iconWidth="'20px'"
      :iconHeight="'20px'"
      ref="container"
      @onIconClick="onIconClick"

  >
    <el-dialog
        :modal-append-to-body="false"
        :append-to-body="false"
        :visible.sync="showDialog">
      <el-form style="width: 500px" :model="exportForm">
        <el-form-item label="导出时间段">
          <el-date-picker
              style="width: 400px"
              size="small"
              v-model="createTimeArr"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="导出数量">
          <el-input style="width: 200px" v-model="exportForm.count" placeholder="不能超过1000条" @input="handleNumberInput"/>
        </el-form-item>

        <el-form-item>
          <el-button @click="exportChat()" type="primary">导出</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </ToolbarItemContainer>
</template>

<script>
import ToolbarItemContainer from "../toolbar-item-container/index.vue";
import exportIcon from "../../../../assets/icon/export.svg";
import TUIChatEngine, {StoreName, TUIStore} from "@tencentcloud/chat-uikit-engine";


export default {
  data() {
    return {
      exportIconFile: exportIcon,
      chatType: '',
      showDialog: false,
      exportForm: {
        startTime: '',
        endTime: '',
        count: 100,
        fromUserId: 0,
        toUserId: 0
      },
      createTimeArr: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }],
      }
    };
  },
  components: {
    ToolbarItemContainer
  },
  computed: {
    timeDefault() {
      var date = new Date();
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate());
    }
  },
  mounted() {
    // 初始化查询，默认为当天
    this.createTimeArr = [this.timeDefault + ' 00:00:00', this.timeDefault + ' 23:59:59']
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: (id) => {
        const conversationInfo = TUIStore.getConversationModel(id)
        if(conversationInfo){
          this.chatType = conversationInfo.type
          if(this.chatType === "C2C"){
            // 只处理私聊
            const currentUser = TUIChatEngine.getMyUserID()
            console.log("currentUser=>",currentUser)
            this.exportForm.fromImUid =currentUser
            this.exportForm.toImUid = conversationInfo.userProfile.userID
          }
        }
      },
    });
  },
  methods: {
    handleNumberInput(e) {
      let value = e.replace(/^(0+)|[^\d]+/g, ''); // 以0开头或者输入非数字，会被替换成空
      value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
      if (Number.parseInt(value) > 1000) {
        value = "1000"
      }
      this.exportForm.count = value
    },
    onIconClick() {
      this.showDialog = true;
    },
    exportChat() {
      this.exportForm.startTime = (this.createTimeArr && this.createTimeArr.length > 0) ? this.createTimeArr[0] : null
      this.exportForm.endTime = (this.createTimeArr && this.createTimeArr.length > 0) ? this.createTimeArr[1] : null

      const exportData = this.exportForm
      console.log("exportData==>",exportData)
      const paramJson = encodeURI(JSON.stringify(exportData).replace(/\+/g, "%2b"))
      window.open(
          this.$http.adornUrl('/admin/virtual/exportRoamMsg') + `?token=${this.$cookie.get('token')}&paramJson=${paramJson}`,
          "_blank"
      );
    }
  }
}
</script>

<style scoped lang="scss">
</style>

