const TUISearch = {
  "发起会话": "Start chat",
  "发起单聊": "New one-to-one chat",
  "发起群聊": "New group chat",
  "添加群聊": "Add a group chat",
  "该用户不存在": "The user does not exist",
  "该群组不存在": "The group does not exist",
  "创建群聊，请注册 TUIGroup 模块": "To create a group chat, please register the TUIGroup module",
  "创建成功": "Creation successful",
  "发起多人会话（群聊）": "New group chat",
  "群头像": "Group profile photo",
  "群名称": "Group name",
  "群ID": "Group ID",
  "群类型": "Group type",
  "选填": "Optional",
  "取消": "Cancel",
  "创建": "Create",
  "类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主神奇。详见": "Similar to a WeChat group. Users can join the group only via invitation by existing members. The invitation does not need to be agreed by the invitee or approved by the group owner. See the documentation for details.",
  "类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见": "Similar to a QQ group. After a public group is created, the group owner can designate group admins. To join the group, a user needs to search the group ID and send a request, which needs to be approved by the group owner or an admin before the user can join the group. See the documentation for details. ",
  "创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见": "After the group is created, a user can join and quit the group freely and can view the messages sent before joining the group. It is suitable for scenarios that integrate Tencent Real-Time Communication (TRTC), such as audio and video conferences and online education. See the documentation for details.",
  "创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见": "After a group is created, a user can join and quit the group freely. The group can have an unlimited number of members, but it does not store message history. It can be combined with Live Video Broadcasting (LVB) to support on-screen comment scenarios. See the documentation for details. ",
  "产品文档": "product documentation",
  "设置群名称": "Group name",
  "请输入群名称": "Enter the group name",
  "设置群ID": "Group ID",
  "请输入群ID": "Enter the group ID",
  "选择群类型": "Group type",
  "陌生人社交群（Public）": "Stranger social group (Public)",
  "临时会议群（Meeting）": "Temporary meeting group (Meeting)",
  "好友工作群（Work）": "Friends work group (Work)",
  "直播群（AVChatroom）": "Live Streaming Group (AVChatroom)",
};

export default TUISearch;
