var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat"},[_c('div',{class:['TUI-chat', !_setup.isPC && 'TUI-chat-h5']},[(!_setup.currentConversationID)?_c('div',{class:['TUI-chat-default', !_setup.isPC && 'TUI-chat-h5-default']},[_vm._t("default")],2):_vm._e(),(_setup.currentConversationID)?_c('div',{class:['TUI-chat', !_setup.isPC && 'TUI-chat-h5']},[_c(_setup.ChatHeader,{class:[
          'TUI-chat-header',
          !_setup.isPC && 'TUI-chat-H5-header',
          _setup.isUniFrameWork && 'TUI-chat-uniapp-header',
        ],attrs:{"humanResponse":_setup.props.humanResponse},on:{"closeChat":_setup.closeChat,"leaveHumanResponse":_setup.leaveHumanResponse}}),_c(_setup.MessageList,{class:[
          'TUI-chat-message-list',
          !_setup.isPC && 'TUI-chat-h5-message-list',
        ],attrs:{"userTags":_setup.props.userTags},on:{"handleEditor":_setup.handleEditor}}),(_setup.isToolbarShow)?_c(_setup.MessageInputToolbar,{class:[
          'TUI-chat-message-input-toolbar',
          !_setup.isPC && 'TUI-chat-h5-message-input-toolbar',
        ],on:{"insertEmoji":_setup.insertEmoji}}):_vm._e(),_c(_setup.MessageInput,{ref:"messageInputRef",class:[
          'TUI-chat-message-input',
          !_setup.isPC && 'TUI-chat-h5-message-input',
          _setup.isUniFrameWork && 'TUI-chat-uni-message-input',
          _setup.isWeChat && 'TUI-chat-wx-message-input',
        ],attrs:{"enableInput":_setup.props.enableInput,"humanResponse":_setup.props.humanResponse,"isMuted":false,"muteText":_setup.TUITranslateService.t('TUIChat.您已被管理员禁言'),"placeholder":_setup.TUITranslateService.t('请输入消息')},on:{"handleToolbarListShow":_setup.handleToolbarListShow,"changeToHumanResponse":_setup.changeToHumanResponse}})],1):_vm._e(),(_setup.isUniFrameWork && _setup.isGroup)?_c('div',{staticClass:"group-profile",on:{"click":_setup.handleGroup}},[_vm._v(" 更多 ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }