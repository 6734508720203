import { render, staticRenderFns } from "./index.vue?vue&type=template&id=29cf29f6&scoped=true&"
import script from "./index.vue?vue&type=script&setup=true&lang=ts&"
export * from "./index.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./style/index.scss?vue&type=style&index=0&id=29cf29f6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29cf29f6",
  null
  
)

export default component.exports