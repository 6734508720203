const TUIGroup = {
 "输入groupID搜索": "Enter groupId search",
  "群名称": "Group name",
  "群ID": "Group ID",
  "加入群聊": "Join",
  "解散群聊": "Dissolution",
  "退出群聊": "Quit",
  "群类型": "Group type",
  "请填写验证信息": "Please fill in the verification information",
  "申请加入": "Apply Join",
  "群公告": "Group notice",
  "群成员": "Group member",
  "群管理": "Manage group",
  "好友工作群": "Work",
  "陌生人社交群": "Public",
  "临时会议群": "Meeting",
  "直播群": "AVChatRom",
  "自由加入": "Join freely",
  "需要验证": "Require approval",
  "禁止加群": "Disallow group joining",
  "人": "",
  "确认": "Confirm",
  "群头像": "Group profile photo",
  "加群方式": "Group joining mode",
  "转让群组": "Transfer ownership",
  "退出群组": "Quit group",
  "群管理员": "Group admin",
  "全员禁言": "Mute All",
  "全员禁言开启后，只允许群主和管理员发言。": "If Mute All is enabled, only the group owner and admin can speak.",
  "单独禁言人员": "Mute a member",
  "删除成员": "Remove member",
  "确定从群聊中删除该成员？": "Are you sure you want to remove this member from the group chat?",
  "确定从群聊中删除所选成员？": "Are you sure you want to remove selected member(s) from the group chat?",
  "暂无公告": "No notice",
  "发布": "Post",
  "编辑": "Edit",
  "查看更多": "View more",
  "管理员": "Admin",
  "群主": "Group owner",
  "我": "me",
  "添加成员": "Add member",
  "新增管理员": "Add admin",
  "移除管理员": "Revoke admin",
  "新增禁言用户": "Add muted member",
  "移除禁言用户": "Remove muted member",
  "修改群聊名称": "Edit group name",
  "修改群聊名称后，将在群内通知其他成员": "After modifying the group chat name, other members will be notified in the group",
  "仅限中文、字母、数字和下划线，2-20个字": "Chinese, letters, numbers and underscores only, 2-20 words",
  "请先注册 TUIGroup 模块": "Please register the TUIGroup module first",
  "该用户不存在": "The user does not exist",
  "该用户不在群组内": "The user is not in the group"
};

export default TUIGroup;
