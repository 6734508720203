var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-video"},[_c('div',{ref:"skeleton",staticClass:"message-video-box",class:[
      !_setup.data.progress &&
        _setup.messageStatus === 'success' &&
        !_vm.isPC &&
        'message-video-cover',
    ],on:{"click":_setup.toggleShow}},[(
        ((_setup.data.progress || _setup.messageStatus !== 'success') && _setup.poster) ||
        (!_vm.isPC && _setup.poster)
      )?_c('img',{staticClass:"message-img",class:[_setup.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_setup.poster}}):(!_vm.isPC)?_c('video',{ref:"video",staticClass:"message-img video-h5-uploading",attrs:{"src":_setup.data.url + '#t=0.1',"poster":_setup.data.url,"preload":"auto","muted":""},domProps:{"muted":true}}):_c('video',{ref:"video",staticClass:"message-img video-web",attrs:{"src":_setup.data.url,"controls":"","preload":"metadata","poster":_setup.poster}}),(_setup.data.progress || _setup.messageStatus !== 'success')?_c('div',{staticClass:"progress"},[(_setup.data.progress)?_c('progress',{attrs:{"max":"1"},domProps:{"value":_setup.data.progress}}):_c('div',{staticClass:"loading"},[_c(_setup.Icon,{attrs:{"file":_setup.loading,"width":"50px","height":"50px"}})],1)]):_vm._e()]),(_setup.show && !_vm.isPC)?_c('div',{staticClass:"dialog-video",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.toggleShow.apply(null, arguments)}}},[_c('header',[_c('i',{staticClass:"icon icon-close",on:{"click":function($event){$event.stopPropagation();return _setup.toggleShow.apply(null, arguments)}}})]),_c('div',{staticClass:"dialog-video-box",class:[!_vm.isPC ? 'dialog-video-h5' : ''],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.toggleShow.apply(null, arguments)}}},[_c('video',{class:[_setup.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_setup.data.url,"controls":"","autoplay":""}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }