/**
 * 开发环境
 */
(function () {
  window.SITE_CONFIG = {}


  if (process.env.NODE_ENV === 'production') {
    window.SITE_CONFIG['baseUrl'] = 'https://admin.4dim.ai/api'
  } else if (process.env.NODE_ENV === 'test') {
    window.SITE_CONFIG['baseUrl'] = 'https://admin.test.4dim.ai/api'
    // window.SITE_CONFIG['baseUrl'] = 'http://157.175.156.103:8083/api'
  } else {
    window.SITE_CONFIG['baseUrl'] = 'http://local.test.4dim.ai/api'
  }

  // cdn地址 = 域名 + 版本号
  window.SITE_CONFIG['domain'] = './' // 域名
  window.SITE_CONFIG['version'] = '' // 版本号(年月日时分)
  window.SITE_CONFIG['cdnUrl'] =
    window.SITE_CONFIG.domain + window.SITE_CONFIG.version
})()
