const TUISearch = {
  "发起会话": "发起会话",
  "发起单聊": "发起单聊",
  "发起群聊": "发起群聊",
  "添加群聊": "添加群聊",
  "该用户不存在": "该用户不存在",
  "该群组不存在": "该群组不存在",
  "创建群聊，请注册 TUIGroup 模块": "创建群聊，请注册 TUIGroup 模块",
  "创建成功": "创建成功",
  "发起多人会话（群聊）": "发起多人会话（群聊）",
  "群头像": "群头像",
  "群名称": "群名称",
  "群ID": "群ID",
  "群类型": "群类型",
  "选填": "选填",
  "取消": "取消",
  "创建": "创建",
  "类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主神奇。详见": "类似普通微信群，创建后仅支持已在群内的好友邀请加群，且无需被邀请方同意或群主神奇。详见",
  "类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见": "类似 QQ 群，创建后群主可以指定群管理员，用户搜索群 ID 发起加群申请后，需要群主或管理员审批通过才能入群。详见",
  "创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见": "创建后可以随意进出，且支持查看入群前消息；适合用于音视频会议场景、在线教育场景等与实时音视频产品结合的场景。详见",
  "创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见": "创建后可以随意进出，没有群成员数量上限，但不支持历史消息存储；适合与直播产品结合，用于弹幕聊天场景。详见",
  "产品文档": "产品文档",
  "设置群名称": "设置群名称",
  "请输入群名称": "请输入群名称",
  "设置群ID": "设置群ID",
  "请输入群ID": "请输入群ID",
  "选择群类型": "选择群类型",
  "陌生人社交群（Public）": "陌生人社交群（Public）",
  "临时会议群（Meeting）": "临时会议群（Meeting）",
  "好友工作群（Work）": "好友工作群（Work）",
  "直播群（AVChatroom）": "直播群（AVChatroom）",
};

export default TUISearch;
