const TUIConversation = {
  "发起单聊": "发起单聊",
  "发起群聊": "发起群聊",
  "请输入userID": "请输入userID",
  "删除会话": "删除会话",
  "置顶会话": "置顶会话",
  "取消置顶": "取消置顶",
  "消息免打扰": "消息免打扰",
  "取消免打扰": "取消免打扰",
  "系统通知": "系统通知",
  "有人@我": "有人@我",
  "@所有人": "@所有人",
  "条": "条",
  "我": "我",
  "网络异常，请您检查网络设置": "网络异常，请您检查网络设置"
}

export default TUIConversation;
