var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"skeleton",staticClass:"message-image",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.toggleShow.apply(null, arguments)}}},[_c('img',{staticClass:"message-img",style:(_setup.isH5
        ? {
            maxWidth: _setup.data.width ? _setup.data.width + 'px' : 'calc(100vw - 180px)',
            maxHeight: _setup.data.height
              ? _setup.data.height + 'px'
              : 'calc(100vw - 180px)',
          }
        : {}),attrs:{"src":_setup.data.url,"width":_setup.data.width,"height":_setup.data.height}}),(_setup.data.progress)?_c('div',{staticClass:"progress"},[_c('progress',{attrs:{"max":"1"},domProps:{"value":_setup.data.progress}})]):_vm._e(),(_setup.show)?_c('div',{staticClass:"dialog",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.toggleShow.apply(null, arguments)}}},[(!_setup.isH5)?_c('header',[_c('i',{staticClass:"icon icon-close",on:{"click":function($event){$event.stopPropagation();return _setup.toggleShow.apply(null, arguments)}}})]):_vm._e(),_c('div',{staticClass:"dialog-box",class:[_setup.isH5 ? 'dialog-box-h5' : ''],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.toggleShow.apply(null, arguments)}}},[_c('img',{class:[_setup.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_setup.data.message.payload.imageInfoArray[0].url},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.toggleShow.apply(null, arguments)}}}),(_setup.isH5)?_c('div',{staticClass:"dialog-box-h5-footer"},[_c('p',{on:{"click":_setup.toggleShow}},[_c('img',{attrs:{"src":require("../../../../assets/icon/close-image.png")}})]),_c('p',{on:{"click":function($event){$event.stopPropagation();return _setup.downloadImage(_setup.data.message)}}},[_c('img',{attrs:{"src":require("../../../../assets/icon/downaload-image.png")}})])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }