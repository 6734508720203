import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import common from './modules/common'
import user from './modules/user'
import httpRequest from '@/utils/httpRequest'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    common,
    user
  },
  state: {
    sysUsers: [],
    virtualUsers: [],
    virtualIps: [],
  },
  mutations: {
    SET_SYS_USER: (state, data) => {
      state.sysUsers = data;
    },
    SET_VIRTUAL_USER: (state, data) => {
      state.virtualUsers = data;
    },
    SET_VIRTUAL_IP: (state, data) => {
      state.virtualIps = data;
    },
    // 重置vuex本地储存状态
    resetStore(state) {
      Object.keys(state).forEach((key) => {
        state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
      })
    }
  },
  actions: {
    GetSysUser({ commit, state }, { refresh, params }) {

      const { sysUsers } = state;
      if (sysUsers.length === 0 || refresh) {
        return httpRequest({
          url: httpRequest.adornUrl(`/sys/user/all`),
          method: 'get'
        }).then(({ data }) => {
          if (data && data.code === 0) {
            commit("SET_SYS_USER", data.result || []);
            return data.result || [];
          }
        })

      } else {
        return Promise.resolve(sysUsers);
      }
    },
    GetVirtualUser({ commit, state }, { refresh, params }) {

      const { virtualUsers } = state;
      if (virtualUsers.length === 0 || refresh) {
        return httpRequest({
          url: httpRequest.adornUrl(`/admin/virtual/all`),
          method: 'get'
        }).then(({ data }) => {
          if (data && data.code === 0) {
            commit("SET_VIRTUAL_USER", data.result || []);
            return data.result || [];
          }
        })

      } else {
        return Promise.resolve(virtualUsers);
      }
    },
    GetVirtualIp({ commit, state }, { refresh, params }) {
      const { virtualIps } = state;
      if (virtualIps.length === 0 || refresh) {
        return httpRequest({
          url: httpRequest.adornUrl(`/admin/virtualIp/all`),
          method: 'get'
        }).then(({ data }) => {
          if (data && data.code === 0) {
            commit("SET_VIRTUAL_IP", data.result || []);
            return data.result || [];
          }
        })
      } else {
        return Promise.resolve(virtualIps);
      }
    }
  },
  strict: process.env.NODE_ENV !== 'production'
})
